* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.reservenow-button {
  padding: 0.9rem 1.75rem;
  background-color: #FFF;
  color:maroon;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 3vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}

.primary-text {
  font-size: clamp(1rem, 2vw, 1.5rem);
  max-width: 500px;
  margin: 1.5rem 0rem;
  font-family: 'Roboto', sans-serif;
  color:#000;
  font-size: 14px;
  font-weight:300px;
  padding: 1rem;
  letter-spacing: 1px;
  line-height: 1.8;
}

.secondary-button {
  padding: 1rem 2.5rem;
  background-color: maroon;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #FFF;
  color:maroon;
}
.about-section-container {
  margin-top: 15rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: maroon;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  /* margin: 6rem 0rem; */
  margin-right:-6rem;
  margin-left:-6rem;
  display: flex;
  margin-top: 0rem;
  background-color: maroon;
  padding:6rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #FFF;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap:50px;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  color:#FFF
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  height: 500px;
  background-color: white;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 1rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight:300px;
  padding: 1rem;
  letter-spacing: 1px;
  line-height: 1.8;
  
}

/*CUSTOM CSS*/
.about-philosophy-container {
  padding: 1rem;
  margin-top: 15rem;
  width:100%;
  position: relative;
  margin-bottom:10rem;
}

.about-philosophyImage{
  height:500px;
  width:500px;
  z-index:-2;
  position: absolute;
  top:50px;
  left: 330px;
  opacity: 0.1;
}

.about-philosophy-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-bottom:20px;
}

.about-philosophy-section p{
  margin: 1rem 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color:#000;
  font-size: 13px;
  font-weight:300px;
  padding: 1rem;
  letter-spacing: 1px;
  line-height: 1.8;
}

/*CUSTOM CSS*/
.about-missionvision-section {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
}

.mission-section{
  background-color: white;
  width:50%;
  height:13rem;
  float:left; 
  display:flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction:column;
  padding:2rem 1rem;
  border-radius: 1rem;
  margin:1rem 1rem;
}
.mission-section p{
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color:#000;
  font-weight:300px;
  padding: 1rem;
  letter-spacing: 1px;
  line-height: 1.8;
}

.vision-section{
  background-color: white;
  width:50%;
  height:13rem;
  float:right;
  display:flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction:column;
  padding:2rem 1rem;
  border-radius: 1rem;
  margin:1rem 1rem;
}

.vision-section p{
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color:#000;
  font-weight:300px;
  padding: 1rem;
  letter-spacing: 1px;
  line-height: 1.8;
}

/** insta feed **/
.insta-feed-section{
  margin-top:15rem;
  padding:2rem;
}

.insta-heading{
  padding:1rem;
  text-align: center;
}

.insta-profile-container{
  display: flex;
  justify-content: start;
  margin:1rem;
  padding:2rem;
}

.insta-profileImage{
  height:5rem;
  width: 5rem;
}

.insta-profileUsername{
  padding-left: 1rem;
}

.insta-followButton-div{
  padding-left:30rem;
  display:flex;
  justify-content: flex-end;
}

.insta-followButton{
  margin:auto;
  height: 3rem;
  width: 7rem;
  border-radius: 10px;
  color:#FFF;
  outline: none;
  border: none; 
  background-color: maroon;
  cursor: pointer;
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 8px; 
  padding: 8px 16px;
}

.Accredited-section-container{
  margin-top:10rem;
  margin-left: -6rem;
  margin-right: -6rem;
  padding-top:2rem;
  padding-bottom:2rem;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 100px;
}
.accredited-image-container{

  height:7rem;
  width:7rem;
  
}


@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }

  .about-philosophy-container {
    padding: 1rem;
    margin-top: 1.5rem;
    width:100%;
    position: relative;
  }
  
  .about-philosophyImage{
    opacity: 0.0;
  }
  
  .about-philosophy-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  
  .about-philosophy-section p{
    margin: 1rem 1rem 1rem;
    flex: 1;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
  
  /*CUSTOM CSS*/
  .about-missionvision-section {
    margin-top: 1rem;
    flex-direction:column;
  }
  
  .mission-section{
    width:100%;
    height:20rem;
    display:flex; 
  }
  
  .vision-section{
    width:100%;
    height:20rem;
    display:flex; 
  }

  .insta-feed-section{
    margin-top:0rem;  
  }

  .insta-profile-container{
    display: flex;
    flex-direction: column;
  }
  
  .insta-profileImage{
    width: 3rem;
    height:3rem;
  }
  
  .insta-profileUsername{
    margin-top: -3rem;
    padding-left:4rem;
  }

  .insta-profileUsername h1{
    font-size: 20px;
  }
  
  .insta-followButton-div{
    margin-left:-36rem;
    margin-top:5px;
  }

  .Accredited-section-container{
    flex-direction: column;
    gap:50px;
  }
  

}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}