.container {
    background-color: #FFF;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 2rem;
    padding-top: 3rem;
    padding-left:3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); */
    border-radius:20px;
    
}

.container img {
    border-radius:10px;
    width: 100%;
    max-height: 230px;
    display: block;
    transition: all 100ms ease-out;
}

.container p {
    font-family: "Reem Kufi", sans-serif;
    font-size: 11px;
    color:#666;
    margin-top:0rem;
    padding-left: 1rem;
}

.container video {
    /* background-color: #FFF;
    padding-top: 15px;
    padding-left:15px;
    padding-right: 15px;
    padding-bottom: 30px; */
    width: 100%;
    max-height: 230px;
    display: block;
    transition: all 100ms ease-out;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); */
    border-radius:10px;
}